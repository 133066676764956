
import { all, fork } from 'redux-saga/effects';
import commonSaga from 'redux/common/saga';
import userAuditTrailSaga from 'containers/konekta/redux/saga';



export default function* rootSaga() {
    yield all([
        fork(commonSaga),
        fork(userAuditTrailSaga)
    ])
}