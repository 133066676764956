import { IComboBoxOption, IDropdownOption } from "office-ui-fabric-react";
import { ActionstepContext } from "redux/common/reducer";

export default class Tools {
    public static DefaultLanguage = 'en-AU';

    public static PopupCenter(url: string, title: string, w: number, h: number): Window | null {
        var left = (window.screen.width / 2) - (w / 2);
        var top = (window.screen.height / 2) - (h / 2);
        return window.open(url, title, `toolbar=no, location=no, directories=no, menubar=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`);
    }

    public static PopupConnectToActionstep(callback: Function) {
        Tools.PopupCenter(`/integrations/actionstep/connect?returnUrl=/PopupConnectionSuccessful`, "Link Konekta to Actionstep", 1050, 700);
        window.addEventListener('message', event => {
            if (event.data === 'konekta_connection_successful') {
                callback();
            }
        });
    }

    public static PopupConnectToPexa(callback: Function) {
        Tools.PopupCenter(`/integrations/pexa/connect`, "Link Konekta to Pexa", 1050, 700);
        window.addEventListener('message', event => {
            if (event.data === 'konekta_connection_successful') {
                callback();
            }
        });
    }

    public static PopupConnectToGlobalX(callback: Function) {
        Tools.PopupCenter(`/integrations/globalx/connect`, "Link Konekta to GlobalX", 1050, 700);
        window.addEventListener('message', event => {
            if (event.data === 'konekta_connection_successful') {
                callback();
            }
        });
    }

    public static ParseBooleanPropertyValue(o: any, propertyName: string): boolean | undefined {
        const value = o[propertyName];
        if (value !== undefined && (typeof value === 'string' || value instanceof String)) {
            const lowerCaseValue = value.toLowerCase();
            if (lowerCaseValue === 'true' || lowerCaseValue === 'yes') {
                return true;
            } else if (lowerCaseValue === 'false' || lowerCaseValue === 'no') {
                return false;
            }
        }

        return undefined;
    }

    public static ParseStringPropertyValue(o: any, propertyName: string): string | undefined {
        const value = o ? o[propertyName] : undefined;
        if (value !== undefined && (typeof value === 'string' || value instanceof String)) {
            return value as string;
        }

        return undefined;
    }

    public static ParseNumberPropertyValue(o: any, propertyName: string): number | undefined {
        const value = o ? o[propertyName] : undefined;
        if (value !== undefined) {
            if (typeof value === 'number') {
                return value;
            } else if (value instanceof Number) {
                if (!isNaN(value as number)) {
                    return value as number;
                }
            } else if (typeof value === 'string' || value instanceof String) {
                const stringValue = (value as string).replace(/\$/g, '').replace(/,/g, '');
                const testValue = parseFloat(stringValue);
                if (!isNaN(testValue)) {
                    return testValue;
                }
            }
        }

        return undefined;
    }

    public static LowerCaseParams(params: any): any {
        const paramsWithKeysInLowerCase: any = [params.length];
        for (const param in params) {
            paramsWithKeysInLowerCase[param.toLowerCase()] = params[param];
        }

        return paramsWithKeysInLowerCase;
    }

    public static GetActionstepContextFromQueryString(params: any): ActionstepContext | null {
        const lowerCaseParams = Tools.LowerCaseParams(params);

        if (lowerCaseParams['actionsteporg'] === undefined) {
            return null;
        }

        const orgKey = lowerCaseParams['actionsteporg'];
        const matterId = lowerCaseParams['matterid'];

        if (orgKey === undefined || orgKey.length < 1) return null;

        let parsedMatterId = parseInt(matterId);

        if (isNaN(parsedMatterId)) {
            return { orgKey };
        } else {
            return {
                orgKey: orgKey,
                matterContext: {
                    id: parsedMatterId,
                }
            };
        }
    }

    public static assign(object: any, path: string | string[], value: any) {
        if (typeof path === "string")
            path = path.split(".");

        while (path.length > 1) {
            object = object[path.shift()!];
        }

        object[path.shift()!] = value;
    }

    public static isNullOrEmptyOrUndefined(value: String | Date | undefined | null): boolean {
        if (value === null || value === undefined)
            return true;

        if (Object.prototype.toString.call(value) === '[object Date]') {
            return isNaN((value as Date).getTime());
        }

        if (typeof value == "string") {
            if (value === "") return true;
        }

        return false;
    }

    public static valueIsInList(value: string | undefined, list: IDropdownOption[] | IComboBoxOption[]): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        for (let i = 0; i < list.length; i++) {
            if (list[i].key === value) {
                return true;
            }
        }

        return false;
    }

    public static formatAsCurrency(number: Number): string {
        return Number(number).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
    }

    public static inIframe(): boolean {
        try {
            if (window.self !== window.top){
                return true;
            } else{
                return false;
            }
        } catch (e) {
            return true;
        }
    }

    public static navigateToLoginIfNotInIframe = (): boolean => {
        if (Tools.inIframe()) {
            return false;
        } else {
            const returnUrlParam = 'returnurl=' + encodeURIComponent(window.location.href);
            const loginUrl = "/Identity/Account/Login?" + returnUrlParam;
            window.location.href = loginUrl;
            return true;
        }
    }

    public static tryFormatDate(value: string | Date, textIfNull: string, convertToStringCallback: Function) {
        if (value instanceof Date) {
            return convertToStringCallback(value);
        } else {
            try {
                let valueAsDate = new Date(value);
                if (valueAsDate.toString() !== 'Invalid Date') {
                    return convertToStringCallback(valueAsDate);
                }
            } finally { }
        }

        // date parsing failed, so return text instead
        if (textIfNull === undefined || textIfNull === null) {
            return "Not set";
        } else {
            return textIfNull;
        }
    }
}
