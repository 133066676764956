import * as React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import history from 'utils/history'
import { initializeIcons } from '@uifabric/icons'

import { AppState } from 'app.types';
import { setUIDefinitions } from 'redux/common/actions'
import { getUIDefinitions } from 'utils/commonInfo';

import { KonektaRoute, RequiredContext } from 'components/konektaRoute';
import HomePage from 'containers/home/index'
import ManageAddOns from 'containers/manage-add-ons';
//import SignupPage from 'containers/signup';
import Webfrom from 'containers/webform/EnhancedWebform';
import ContactUsPage from 'containers/contactus';
import MatterPage from 'containers/matter';
import { DataKonekta } from 'containers/manage-add-ons/pages/dataKonekta';
import UserAuditTrailTable from 'containers/konekta/userAuditTrailTable';
import NavHeader from 'components/layout/navHeader'
import Footer from 'components/layout/footer'

import TelemetryProvider from 'components/telemetry-provider';

import "./App.css"
import { Stack, IStackTokens } from 'office-ui-fabric-react';


type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

type State = {
	fullPage: boolean;
}

export class App extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		initializeIcons();

		this.state = {
			fullPage: false,
		}
	}

	public async componentDidMount(): Promise<void> {
		if (this.props.uiDefinitions === undefined) {
			try {
				const uiDefinitions = await getUIDefinitions();
				this.props.setUIDefinitions(uiDefinitions);
			} catch (e) {
				console.log('Konekta: Unknown error getting UI Definitions:', e);
			}
		}
	}

	render() {
		const { fullPage } = this.props;

		const MainStackTokens: IStackTokens = {
			childrenGap: 'm',
			padding: 10,
		};

		return (
			<div className={fullPage ? 'full-page-wrapper' : 'normal-page-wrapper'}>
				<Router history={history}>
					<TelemetryProvider instrumentationKey={(window as any).appInsightsInstrumentationKey}>
						<NavHeader />
						<Stack tokens={MainStackTokens}>
							<Switch>
								{/* Anonymous pages */}
								{/* <KonektaRoute allowAnonymous path="/signup" component={SignupPage} /> */}
								<Redirect path="/signup" to="/contactus" />
								<KonektaRoute fullPage allowAnonymous path="/EnhencedWebform" component={Webfrom} />
								<KonektaRoute fullPage allowAnonymous path="/contactus" component={ContactUsPage} />
								{/* Matter - specific (usually used from the Actionstep iframe) */}
								<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/matter" component={MatterPage} />
								<KonektaRoute requiredContext={RequiredContext.OrgAndMatter} path="/konekta/user-audit-trail" component={UserAuditTrailTable} />
								{/* Actionstep org specific (requires an org to be selected) */}
								<KonektaRoute requiredContext={RequiredContext.Org} path="/manage-add-ons/data-konekta" component={DataKonekta} />
								<KonektaRoute requiredContext={RequiredContext.Org} path="/manage-add-ons" component={ManageAddOns} />

								{/* General standalone pages (own tab/window) */}
								<KonektaRoute path="/" exact component={HomePage} />

								{/* Fallback */}
								<Redirect to="/" />
							</Switch>
						</Stack>
						<Footer />
					</TelemetryProvider>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	return {
		fullPage: state.common.fullPage,
		uiDefinitions: state.common.uiDefinitions,
	}
}

const mapDispatchToProps = {
	setUIDefinitions,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);