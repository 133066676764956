import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router'
import { History } from 'history';

import { AppState, AppActionTypes } from 'app.types';
import commonReducer from 'redux/common/reducer';
import userAuditTrailReducer from 'containers/konekta/redux/reducer';

const createRootReducer = (history: History<any>): Reducer<AppState, AppActionTypes> => combineReducers(
    {
        router: connectRouter(history),
        common: commonReducer,
        userAuditTrail: userAuditTrailReducer
    }
)

export default createRootReducer