import * as React from 'react'
import { connect } from 'react-redux';

import { AppState } from 'app.types';
import { IContactUsCommand } from 'utils/wcaApiTypes';
import Tools from 'utils/tools';

import { DefaultStackTokens } from '../../konekta-theme';
import { Text, Stack, TextField, Label, Checkbox, IStackTokens, IStackStyles, PrimaryButton, SpinnerSize, Spinner } from 'office-ui-fabric-react';
import { rawPost } from 'utils/request';
import ErrorMessageWidget from 'components/common/errorMessageWidget';

import './index.css';

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

enum FormState {
    Editable = 0,
    Submitting,
    SubmittedSuccess,
    SubmitError,
}

type IState = {
    formState: FormState,
    formDataIsValid: boolean,
    validationErrors?: string[] | undefined,
    contactUsCommand: IContactUsCommand;
}

export class ContactUsPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            formState: FormState.Editable,
            formDataIsValid: false,
            contactUsCommand: {
                interestedInGlobalXIntegration: false,
                interestedInPEXAIntegration: false,
                interestedInInfoTrackIntegration: false,
                interestedInFirstTitleIntegration: false,
                interestedInQuickFeeIntegration: false,
                interestedInEmailToSMSIntegration: false,
                interestedInTheSearchPeopleIntegration: false,
                interestedInLiveSignIntegration: false,
                interestedInPerfectPortalIntegration: false,
                interestedInPexaKeyIntegration: false,
                interestedInSecureExchangeIntegration: false,
                interestedInACTConveyancingAddOn: false,
                interestedInQLDConveyancingAddOn: false,
                interestedInNSWConveyancingAddOn: false,
                interestedInVICConveyancingAddOn: false,
                interestedInSafeCustodyAddOn: false,
                interestedInQLDUCPRFormsAddOn: false,
          
            },
        }
    }

    private validate = (): string[] => {
        var errors: string[] = [];
        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.firstname)) {
            errors.push('First name must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.lastname)) {
            errors.push('Last name must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.email)) {
            errors.push('Email must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.company)) {
            errors.push('Company must be supplied');
        }

        if (Tools.isNullOrEmptyOrUndefined(this.state.contactUsCommand.phone)) {
            errors.push('Phone must be supplied');
        }

        return errors;
    }

    private submit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        const validationErrors = this.validate();
        if (validationErrors.length > 0) {
            this.setState({
                formState: FormState.Editable,
                validationErrors,
            });

            return;
        }

        this.setState({ formState: FormState.Submitting });

        try {
            await rawPost('/api/customer/contact-us', this.state.contactUsCommand, {});
            this.setState({ formState: FormState.SubmittedSuccess });
        } catch (e) {
            if (e.errorList && Array.isArray(e.errorList)) {
                this.setState({
                    validationErrors: e.errorList,
                    formState: FormState.Editable,
                });
            } else {
                console.log('Unknown submitting form. Details:', e);
                this.setState({
                    validationErrors: undefined,
                    formState: FormState.SubmitError
                });
            }
        }
    }

    render(): JSX.Element {
        const checkBoxStackTokens: IStackTokens = {
            childrenGap: 3,
        }

        const containerTokens: IStackTokens = {
            childrenGap: 'l',
        }

        const outerContainerStyles: IStackStyles = {
            root: {
                alignItems: 'center',
                marginBottom: 100,
            }
        }

        const innerContainerStyles: IStackStyles = {
            root: {
                width: 450,
            }
        }

        const sectionStyles: IStackStyles = {
        }

        if (this.state.formState === FormState.SubmittedSuccess) {
            return (
                <Stack styles={outerContainerStyles} tokens={containerTokens}>
                    <Stack styles={innerContainerStyles} tokens={DefaultStackTokens}>
                        <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                            <h1>Contact Us</h1>
                            <h2>Form submitted successfully</h2>
                            <Text>
                                Thank you! Someone from Konekta will be in touch soon.
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            )
        } else {
            const disableSubmit = this.state.formState === FormState.Submitting
                || (this.state.validationErrors && this.state.validationErrors.length > 0);

            return (
                <form onSubmit={this.submit}>
                    <Stack styles={outerContainerStyles} tokens={containerTokens}>
                        <Stack styles={innerContainerStyles} tokens={DefaultStackTokens}>
                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                <h1>Contact Us</h1>
                                <Text>
                                    Thanks for your interest in Konekta integrations and add-ons.
                                </Text>
                            </Stack>
                            <Stack className="contact-us-fields-container" styles={sectionStyles} tokens={checkBoxStackTokens}>
                                <TextField label="First Name"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, firstname: v } })}
                                    value={this.state.contactUsCommand.firstname}
                                    maxLength={100} required underlined />

                                <TextField label="Last Name"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, lastname: v } })}
                                    value={this.state.contactUsCommand.lastname}
                                    maxLength={100} required underlined />

                                <TextField label="Email"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, email: v } })}
                                    value={this.state.contactUsCommand.email}
                                    maxLength={255} required underlined />

                                <TextField label="Company"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, company: v } })}
                                    value={this.state.contactUsCommand.company}
                                    maxLength={100} required underlined />

                                <TextField label="Phone"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, phone: v } })}
                                    value={this.state.contactUsCommand.phone}
                                    maxLength={20} required underlined />

                                <TextField label="Actionstep Organisation Key"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, orgKey: v } })}
                                    value={this.state.contactUsCommand.orgKey}
                                    maxLength={20} underlined />

                                <TextField label="Address Line1"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, line1: v } })}
                                    value={this.state.contactUsCommand.line1}
                                    maxLength={20} underlined />

                                <TextField label="Address Line2"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, line2: v } })}
                                    value={this.state.contactUsCommand.line2}
                                    maxLength={20} underlined />

                                <TextField label="Suburb/City"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, suburb: v } })}
                                    value={this.state.contactUsCommand.suburb}
                                    maxLength={20} underlined />

                                <TextField label="State"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, state: v } })}
                                    value={this.state.contactUsCommand.state}
                                    maxLength={20} underlined />

                                <TextField label="Post Code"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, postCode: v } })}
                                    value={this.state.contactUsCommand.postCode}
                                    maxLength={20} underlined />
                            </Stack>
                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                <Label><strong>Integrations</strong> you're interested in?</Label>
                                <Checkbox label="PEXA"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPEXAIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInPEXAIntegration} />
                                <Checkbox label="InfoTrack"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInInfoTrackIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInInfoTrackIntegration} />
                                <Checkbox label="Dye & Durham"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInGlobalXIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInGlobalXIntegration} />
                                <Checkbox label="First Title"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInFirstTitleIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInFirstTitleIntegration} />
                                <Checkbox label="livesign"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInLiveSignIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInLiveSignIntegration} />                              
                                <Checkbox label="Perfect Portal"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPerfectPortalIntegration: v ?? false } })}
                                    checked={this.state.contactUsCommand.interestedInPerfectPortalIntegration} />
                                <Checkbox label="Secure Exchange"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInSecureExchangeIntegration: v ?? false } })}      
                                    checked={this.state.contactUsCommand.interestedInSecureExchangeIntegration} />
                                <Checkbox label="Pexa Key"
                                    onChange={(e, v) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, interestedInPexaKeyIntegration: v ?? false } })}      
                                    checked={this.state.contactUsCommand.interestedInPexaKeyIntegration} />
                            </Stack>

                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                <TextField label="Message (Optional)"
                                    onBlur={(e) => this.setState({ contactUsCommand: { ...this.state.contactUsCommand, message: e.currentTarget.value } })}
                                    maxLength={2000}
                                    multiline autoAdjustHeight />
                            </Stack>

                            <Stack styles={sectionStyles} tokens={checkBoxStackTokens}>
                                {this.state.formState === FormState.SubmitError &&
                                    <Stack.Item>
                                        <ErrorMessageWidget message="Oops! We're sorry, something went wrong." />
                                    </Stack.Item>
                                }
                                {this.state.validationErrors && this.state.validationErrors.length > 0 &&
                                    <ul>
                                        {this.state.validationErrors.map(e =>
                                            <li key={e} className="errorMessage">{e}</li>
                                        )}
                                    </ul>
                                }
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Submit"
                                        type="submit"
                                        allowDisabledFocus={true}
                                        default
                                        disabled={disableSubmit} />
                                    {this.state.formState === FormState.Submitting &&
                                        <Spinner size={SpinnerSize.small} />
                                    }
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            )
        }
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        accountInfo: state.common.accountInfo,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);