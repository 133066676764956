import * as React from 'react';
import sanitize from 'sanitize-html';
import { MessageBar } from 'office-ui-fabric-react';
import {  useState } from 'react';
import { getRequest } from 'utils/request';
import "./promotion.css";

class PromotionRecord {
 id: number | "" | undefined;
  message : string | "" | undefined;
}

const Promotions: React.FunctionComponent<any> = (props) => {
  let promotionObj: PromotionRecord[] = [];
  const [data, setPromotionDataObject] = useState([]); 
  Object.assign(promotionObj, data);
  React.useEffect(() => {   
      (async () => {      
          const settingsFromServer = await getRequest(`/api/data-konekta/promotion-data`)
          setPromotionDataObject(settingsFromServer);
        
      })();
  },[]) 
 
  return (
    <>
    <div className='announcement-header-margin'> 
      {promotionObj != null && promotionObj.length > 0 &&
        <MessageBar className="announcement-header">
          <span className="announcement-heading">Announcement</span>
          {promotionObj.map(d => (
            <div className="announcement-body" key={d.id}>
              <span dangerouslySetInnerHTML={{
                __html: sanitize(d.message??"",
                  {
                    allowedTags: ['p', 'span', 'a'],
                    allowedAttributes: {
                      'p': ["style"],
                      'span': ["style"],
                      'a': ["href", "target"]
                    },
                    allowedStyles: {
                      '*': {
                        // Match HEX and RGB
                        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                        'text-align': [/^left$/, /^right$/, /^center$/],
                        // Match any number with px, em, or %
                        'font-size': [/^\d+(?:px|em|%)$/],
                        'font-weight':[/^bold$/],
                        'padding': [/^\d+(?:px|em|%)$/]
                      }
                    }
                  })
              }} />
            </div>
          ))}
        </MessageBar>
      }
      </div>
    </>
  );
}
export default Promotions;

